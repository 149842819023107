<template>
  <div class="wrapper">
      <div class="content">
          <!-- <div class="menu">
              <div class="item">ABOUT US</div>
              <div class="item">FOR COMPANIES</div>
              <div class="item">FOR STUDENTS</div>
              <div class="item">CONTACTO</div>
          </div> -->
          <div class="addition">
              <div class="brand">
                  <div class="logo">
                      <img src="@/assets/images/logo-cumlaude.svg" alt="">
                  </div>
                  <div class="text">
                        {{ $t('funky_text_body') }}
                  </div>
              </div>
              <div class="urls">
                  <div class="list">
                      <div><strong>{{ $t('resources_footer') }}</strong></div>
                      <div class="resources">
                          <a>{{ $t('tandc_footer') }}</a>
                            <a>{{ $t('privacy_policy_footer') }}</a>
                      </div>
                                            
                  </div>
                  <div class="list">
                      <div><strong>{{ $t('social_title_footer') }}</strong></div>
                      <div class="sm-icons">
                        <img src="@/assets/icons/facebook-icon.svg" alt="">
                        <img src="@/assets/icons/insta-icon.svg" alt="">
                        <img src="@/assets/icons/linkedin-icon.svg" alt="">
                      </div>
                  </div>
              </div>
          </div>
          <div class="copy">
              © 2021 CumLaude
          </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.wrapper{
    background-color: #395359;
    .content{
        color: white;
        font-size: 0.8rem;
        .menu{
             display: flex;
             justify-content: center;
             @media screen and (max-width: 880px) {
                 display: none;
             }
             
             .item{
                 margin: 17px;
             }
        }
        .addition{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 40px;
            @media screen and (max-width: 880px) {
                flex-direction: column;
            }
            .brand{
                width: 30%;
                @media screen and (max-width: 880px) {
                    width: 100%;
                    margin-top: 15px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
                .logo{
                    text-align: left;
                    margin-top: 20px;
                    img{
                        width: 70%;
                        margin-bottom: 8px;
                        @media screen and (max-width: 880px) {
                            width: 100%;
                            margin-bottom: 5px;
                        }
                    }
                }
                .text{
                    text-align: left;
                    @media screen and (max-width: 880px) {
                        text-align: justify;
                    }
                }
            }
            .urls{
                width: 65%;
                display: flex;
                justify-content: space-evenly;
                font-size: 1.1em;
                @media screen and (max-width: 880px) {
                    width: 100%;
                    margin-top: 20px;
                }
                .list{
                    width: 30%;
                    text-align: left;
                    @media screen and (max-width: 880px) {
                        width: 50%;
                    }
                    .resources {
                        display: flex;
                        flex-direction: column;
                        font-size: 1em;
                        a {
                            margin-top: 10px;
                        }
                    }
                    .sm-icons {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        margin-top: 15px;
                    }
                    // margin-top: 50px;
                }

            }
        }
        .copy{
            font-size: 0.6rem;
            padding: 10px;
        }
    }
}
</style>
