import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import RouterView from "../views/RouterView.vue";
import Redirect from "../views/Redirect.vue"
import { i18n } from "../main.js";

Vue.use(VueRouter)

const routes = [
    {
    path: '/',
    name: 'base',
    component: Redirect
  },
 
  {
      path: "/:lang",
      component: RouterView,
      beforeEnter(to, from, next) {
        const lang = to.params.lang;
        if (!["en", "es"].includes(lang)) return next("es");
        if (i18n.locale !== lang) {
          i18n.locale = lang;
        }
        return next();
      },
      children: [
        {
          path: "/",
          name: "home",
          component: Home
        },
        {
          path: 'about',
          name: 'About',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
        },
        {
          path: 'institutions',
          name: 'Institutions',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '../views/Institutions.vue')
        },
        {
          path: 'companies',
          name: 'Companies',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '../views/Companies.vue')
        },
        {
          path: 'students',
          name: 'Students',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '../views/Students.vue')
        },
        {
          path: 'contact',
          name: 'Contact',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '../views/Contact.vue')
        },
        {
          path: 'submission',
          name: 'Contact Form',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '../views/Submission.vue')
        },
      ]
    
  },
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home
  // },
  // {
  //   path: '/',
  //   redirect: '/es/about'
  // },

  {
    path: "*",
    redirect: "/es"
  }
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  linkExactActiveClass: 'is-active',
  routes
})

export default router
