<template>
  <div id="app">
    <Navbar />
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <router-view/>
    <Footer />
    <Sidebar/>
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import Sidebar from '@/components/Sidebar.vue'




export default {
  name: 'Home',
  components: {
    Navbar,
    Footer,
    Sidebar
  },
  mounted() {
    window.addEventListener("scroll", function () {
      const animatedEls = document.getElementsByClassName('animate')
      // const viewHeight = window.scrollY + window.innerHeight
      animatedEls.forEach( el =>{
        var animationOffset = 600
        // if(el.id=='test'){
        //   console.log(viewHeight, el.offsetTop, el.offsetHeight, el.getBoundingClientRect().top)
        // }
        const additionalOffset = el.getAttribute('data-animation-offset')
        if(additionalOffset) { animationOffset += (additionalOffset*1)}
        // if(el.getBoundingClientRect().top < (viewHeight-animationOffset)) {
        //   el.classList.add('animated')
        // }
        if(el.getBoundingClientRect().top < (animationOffset)){
           el.classList.add('animated')
        }
      })
    })
    }
}
</script>

<style lang="scss">
#app {
  overflow-x: hidden
}
@import "@/assets/styles/variables"; 
// @import "../../node_modules/@egjs/vue-flicking/dist/flicking.css";
@font-face {
  font-family: 'Museo';
  src: url('./assets/fonts/Museo300-Regular.otf');
  font-weight: 300;
}

@font-face {
  font-family: 'Museo';
  src: url('./assets/fonts/Museo700-Regular.otf');
  font-weight: 700;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Bold.ttf');
  font-weight: bold;
}
@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Regular.ttf');
  font-weight: regular;
}
@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-SemiBold.ttf');
  font-weight: semi-bold;
}

#app {
  font-family: Museo, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.wrapper{
    .content{
        width: 70vw;
        max-width: 1100px;
        margin: auto;
        @media screen and (max-width: $mobile-width-1)  {
            width: 90vw;
        }
    }
}

button{
  border-radius: 5px;
  border: none;
  padding: 10px;
  background-color: #395359;
  color: white;
  &:hover {
    box-shadow: 0px 0px 3px 0px rgba($color: #000000, $alpha: 0.3);
    cursor: pointer;
  }
  &.light{
    background-color: #56828c;
  }
  &.green{
     background-color:#838c3f;
  }
  &.dark{
    background-color: #395359; 
  }

}

.animate{
  opacity: 0;
  // visibility: hidden;
  transition: all 0.7s ease-in-out;
}

.animated{
  opacity: 1;
  visibility: visible;
  &[data-animation="downUp"]{
    animation: downUp 0.7s;
    opacity: 1;
  }
  &[data-animation="leftRight"]{
    animation: leftRight 0.5s;
    opacity: 1;
  }
  &[data-animation="rightLeft"]{
    animation: rightLeft 0.5s;
    opacity: 1;
  }
  &[data-animation-delay="100"]{
    animation-delay: 100ms;
    transition-delay: 100ms;
  }
  &[data-animation-delay="200"]{
    animation-delay: 200ms;
    transition-delay: 200ms;
  }
  &[data-animation-delay="300"]{
    animation-delay: 300ms;
    transition-delay: 300ms;
  }
  &[data-animation-delay="400"]{
    animation-delay: 400ms;
    transition-delay: 400ms;
  }
}

@keyframes downUp {
  0% { transform: translate3d(0, 100px, 0); }
  100% { transform: translate3d(0, 0, 0); }
}

@keyframes leftRight {
  0% { transform: translate3d(-200px, 0, 0); }
  100% { transform: translate3d(0, 0, 0); }
}

@keyframes rightLeft {
  0% { transform: translate3d(200px, 0, 0); }
  100% { transform: translate3d(0, 0, 0); }
}

</style>
