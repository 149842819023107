<template>
  <div class="navWrapper">
    <div class="content">
      <div class="logo">
        <router-link :to="'/' + $i18n.locale ">
          <img src="@/assets/images/logo-cumlaude.svg" alt="">
        </router-link>
      </div>
      <div class="menu">
        <div class="link">
          <router-link :to="'/' + $i18n.locale + '/about'">
            {{ $t('nav_about') }}
          </router-link>
        </div>
        <div class="link">
          <router-link :to="'/' + $i18n.locale + '/institutions'">{{ $t('nav_institutions') }}</router-link>
        </div>
        <div class="link">
          <router-link :to="'/' + $i18n.locale + '/companies'">{{ $t('nav_companies') }}</router-link> 
        </div>
        <div class="link">
          <router-link :to="'/' + $i18n.locale + '/students'">{{ $t('nav_students') }}</router-link>  
        </div>
        <div class="link">
          <router-link :to="'/' + $i18n.locale + '/contact'">{{ $t('nav_contact') }}</router-link>
          
        </div>
        <div class="flags">
          <img v-on:click="changeLang()" class="first" src="../assets/icons/spain-round-icon.svg" alt="Spanish">  
          <img v-on:click="changeLang()" src="../assets/icons/united-kingdom.svg" alt="English">
        </div>
        <div class="menu-mobile" @click="toggleSidebar()">
              <img src="@/assets/images/menu_black.svg" alt="">
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Navbar',
  methods: {
    toggleSidebar: function () {
      console.log('toggleing')
      document.getElementById("sidebar").classList.toggle("active");
    },
    changeLang(){
          if(this.$i18n.locale == 'en'){
              this.$i18n.locale = 'es';
          } else {
              this.$i18n.locale = 'en';
          }
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.navWrapper{
  z-index: 2500;
  position: fixed;
  height: 50px;
  width: 100vw;
  background-image: linear-gradient(180deg, #395359d3, #3953595b);
  .content{
    display: flex;
    height: 100%;
    width: 80%;
    margin: auto;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 990px) {
      width: 90%;
    }
    .logo{
      height: 80%;
      img{
        height: 100%;
      }
    }
    .menu{
      width: 65%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #f2f2f2;
       @media screen and (max-width: 880px) {
                   justify-content: flex-end;
                }
      .link{
        @media screen and (max-width: 880px)  {
          display: none;
        }
        a{
        color: #f2f2f2;
        text-decoration: none;
        font-weight: 600;
        &:hover {
          border-bottom: 2px solid #fff;
        }
      }
      }
      .flags {
        display: flex;
        padding:6px;
        img {
          width: 17px;
          padding:0 8px;
        }
        img:hover {
          cursor: pointer;
        }
        .first {
          border-right: 1px solid white;
        }
        @media screen and (max-width: 880px) {
          display:none;
        }
      

      }
      .menu-mobile{
            display: none;
            @media screen and (max-width: 880px) {
                   display: block;
                   margin-top: 8px;
                }
        }
      
    }
  }
}
</style>
