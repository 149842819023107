<template>
  <div class="wrapper">
    <div class="white-waves">
      <img src="@/assets/images/white-lines.svg" alt="" />
    </div>
    <div class="content">
      <h2>{{ $t('philosophy_title')}}</h2>
      <p>
        {{ $t('philosophy_sub_title')}}
      </p>
      
      <div class="overview">
        <div class="cards">
          <router-link :to="'/' + $i18n.locale + '/about'" class="card animate" data-animation="downUp">
            <div class="box-left">
              <div class="icon">
                <img src="@/assets/images/ico-peripatetic.svg" alt="" />
              </div>
              <div class="text">
                <h3>{{ $t('peripatetic_title')}}</h3>
                <p>
                  {{ $t('peripatetic_body')}}
                </p>
              </div>
            </div>
            <div class="image">
              <img src="@/assets/images/outside_render_sm.jpg" alt="" />
            </div>
            <!-- <button class="green learn">Learn more</button> -->

          </router-link>

          <router-link :to="'/' + $i18n.locale + '/about'" class="card animate" data-animation="downUp">
            <div class="box-left">
              <div class="icon">
                <img src="../../assets/images/ico-nature-fun.svg" alt="" />
              </div>
              <div class="text">
                <h3>{{ $t('nature&fun_title')}}</h3>
                <p>
                  {{ $t('nature&fun_body')}}
                </p>
              </div>
            </div>
            <div class="image">
              <img src="../../assets/images/walking_in_nature.jpg" alt="" />
            </div>
            <!-- <button class="green learn">Learn more</button> -->
          </router-link>

          <router-link :to="'/' + $i18n.locale + '/about'" class="card animate" data-animation="downUp">
            <div class="box-left">
              <div class="icon">
                <img src="@/assets/images/ico-agilelearning.svg" alt="" />
              </div>
              <div class="text">
                <h3>{{ $t('agile_learning_title')}}</h3>
                <p>
                  {{ $t('agile_learning_body')}}
                </p>
              </div>
            </div>
            <div class="image">
              <img src="@/assets/images/Agile-learning_sm.jpg" alt="" />
            </div>
          </router-link>
        </div>
      </div>
        <router-link to="/es/about" tag="button" class="light more">{{ $t('button_title')}}</router-link>
      <h2>{{ $t('title_home_images') }}</h2>
    </div>

    <div style="position: relative">
      <flicking
        :options="{ circular: true, gap: 50, duration: 500 }"
        :plugins="this.plugins"
        class="flicking"
        ref="flick"
      >
        <div class="panel">
          <expandable-image :src="require('@/assets/images/slider/1.jpg')" />
        </div>
        <div class="panel">
          <expandable-image :src="require('@/assets/images/slider/2.jpg')" />
        </div>
        <div class="panel">
          <expandable-image :src="require('@/assets/images/slider/3.jpg')" />
        </div>
        <div class="panel">
          <expandable-image :src="require('@/assets/images/slider/4.jpg')" />
        </div>
        <div class="panel">
          <expandable-image :src="require('@/assets/images/slider/5.jpg')" />
        </div>
      </flicking>
      <div class="controls-wrapper">
        <div class="controls">
          <img @click="prev" src="@/assets/images/chevron_left.svg" alt="" />
          <img
            @click="next"
            src="@/assets/images/chevron_left.svg"
            alt=""
            class="turn"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Fade, AutoPlay } from "@egjs/flicking-plugins";
export default {
  name: "Philosophy",
  data() {
    return {
      plugins: [
        new Fade(),
        new AutoPlay({ duration: 2000, direction: "NEXT", stopOnHover: true }),
      ],
    };
  },
  methods: {
    next() {
      // You can call it like this
      this.$refs.flick.next();
    },
    prev() {
      this.$refs.flick.prev();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.wrapper {
  position: relative;
  background-image: linear-gradient(0deg, white 35%, #e4f2f2 50%, white 100%);
  .white-waves {
    position: absolute;
    z-index: 0;
    top: 30px;
    left: 0;
    width: 100vw;
    img {
      width: 100%;
    }
  }
  .content {
    // height: 500px;
    z-index: 1;
    position: relative;
    padding-top: 10px;
    .overview {
      display: flex;
      justify-content: center;
      width: 100%;
      z-index: 2;
      @media screen and (max-width: 940px) {
        flex-direction: column;
      }
      .cards {
        display: flex;
        flex-direction: column;
        width: 80%;
        height: auto;
        align-items: center;
        justify-content: space-around;
        background-color: transparent;
        margin: 20px 0;
        @media screen and (max-width: 940px) {
          width: 100%;
          height: fit-content;
        }

        .card {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-radius: 15px;
          border: none;
          height: auto;
          transition: all 0.5s;
          width: 70%;
          margin-top: 15px;
          box-shadow: 0px 0px 4px 2px rgba($color: #395359, $alpha: 0.3);
          overflow: hidden;
          text-decoration: none;
            &:hover {
                box-shadow: none;
                text-decoration: none;
            }
            &:visited { text-decoration: none; color: unset;}
            &:focus { text-decoration: none; }
            &:hover, a:active { text-decoration: none; }
          .learn {
            width: 150px;
            top: 15%;
            left:39%;
            position: absolute;
            box-shadow: 0px 0px 3px 1px rgba($color: #000000, $alpha: 0.3);
          }
          @media screen and (max-width: 940px) {
            width: fit-content;
            max-width: 100%;
            flex-direction: column;
            height: fit-content;
            align-items: center;
            text-align: center;
            margin: auto;
            margin-bottom: 20px;
          }
          .box-left {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            margin-left: 35px;
            width: 45%;
            padding: 15px;
            @media screen and (max-width: 940px) {
                align-items: center;
                width: 75%;
                padding: 15px 0 0 0;
                margin-left: unset;
            }
          }
          .icon {
            margin-bottom: 5%;
            width: 80px;
          }
          .text {
            text-align: left;
            @media screen and (max-width: 940px) {
              margin-left: 0px;
              text-align: center;
              max-width: 300px;
            }
            h3 {
              margin-top: 0px;
            }
          }
          &:hover {
            background-color: white;
          }
        }
      }
      .image {
        border-radius: 0px 15px 15px 0px;
        overflow: hidden;
        display: flex;
        justify-content: flex-end;
        width: 55%;
        height: 260px;
        margin-right: -15px;
        // height: 300px;
        // display: flex;
        @media screen and (max-width: 940px) {
          width: 100%;
          margin-left: 0px;
          height: 250px;
          margin-top: 30px;
          border-radius: 0px 0px 15px 15px;
          margin-right:unset;
        }
        img {
          // height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }
    }
  }
  .more {
    width: 200px;
    font-size: 1.2em;
    margin: 20px 0;
  }
  .flicking {
    //  height: 150px;
    margin: 40px 0;
    .panel {
      width: 400px;
      height: 200px;
      img {
        object-fit: cover;
        max-height: 100%;
        max-width: 100%;
      }
    }
  }
  .controls-wrapper {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    @media screen and (max-width: 480px) {
      display: none;
    }
    .controls {
      display: flex;
      margin: auto;
      justify-content: space-between;
      align-items: center;
      width: 460px;
      // margin-left: 10px;
      height: 100%;
      img {
        z-index: 2001;
        width: 30px;
        cursor: pointer;
        @media screen and (max-width: 480px) {
          width: 20px;
        }
      }
      .turn {
        transform: rotate(180deg);
      }
    }
  }
}
</style>
