import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueFlicking from "@egjs/vue-flicking";
import VueGtag from "vue-gtag";
// import "@egjs/vue-flicking/dist/flicking.css";
import VueCarousel from 'vue-carousel';

import VueI18n from 'vue-i18n';
import messages from './lang';

import VShowSlide from 'v-show-slide';
import VueExpandableImage from 'vue-expandable-image';


Vue.config.productionTip = false
Vue.use(VueFlicking);
Vue.use(VueCarousel);
Vue.use(VShowSlide);
Vue.use(VueExpandableImage)


Vue.use(VueGtag, {
  config: { id: "G-CL4T70HY3P" }
});

Vue.use(VueI18n);
export const i18n = new VueI18n({
  locale: 'es',
  fallbackLocale: 'es',
  messages
});

new Vue({
  router,
  i18n,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')
