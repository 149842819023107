<template>
  <div>

      <iframe style="width: 100vw; height: 350px; border: 0;" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3054.0125310776825!2d-5.014252385041186!3d40.05282128561563!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd4009e15871c7cd%3A0xfad224448935eff0!2sEl%20Toril%20Glamping%20Experience!5e0!3m2!1sen!2sat!4v1625568781476!5m2!1sen!2sat"   allowfullscreen="" loading="lazy"></iframe>
    <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3054.0125310776825!2d-5.014252385041186!3d40.05282128561563!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd4009e15871c7cd%3A0xfad224448935eff0!2sEl%20Toril%20Glamping%20Experience!5e0!3m2!1sen!2sat!4v1625568781476!5m2!1sen!2sat" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe> -->

  </div>
</template>

<script>
export default {
  name: 'Map',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
