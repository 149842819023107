<template>
  <div class="wrapper">
      <div class="content">
          <h2>{{ $t('title_home_partners') }}</h2>
          <!-- <img src="@/assets/images/Home-logos.png" alt=""> -->
          <div class="partners">
              <div class="partner">
                  <img src="@/assets/images/six-sigma-logo.jpg" alt="">
              </div>
              <div class="partner">
                  <img src="@/assets/images/cervantes-logo.jpg" alt="">
              </div>
          </div>
          <div style="margin-top:40px">
              {{ $t('more_soon') }}
          </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'Partners',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.wrapper{
    .content{
        padding: 50px 0;
        img{
            width: 450px;
        }
        .partners{
            display: flex;
            justify-content: space-evenly;
            @media screen and (max-width: 900px)  {
                flex-direction: column;
            }
            .partner{
                height: 150px;
                max-width: 100%;
                @media screen and (max-width: 900px)  {
                margin-bottom: 40px;
            }
                img{
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                }
            }
        }
    }
}
</style>
