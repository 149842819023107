<template>
  <div class="wrapperCover">
      <div class="content">
          <div class="text animate animated" data-animation="leftRight">
              <h1 style="margin: 0px;">{{ $t('home_title') }}</h1>
              <p>{{ $t('sub_title')}}</p>
                <router-link :to="'/' + $i18n.locale + '/about'"><button>{{ $t('button_title')}}</button></router-link>
              
          </div>
      </div>
      <div class="wave">
          <img src="@/assets/images/waves.svg" alt="">
      </div>
  </div>
</template>

<script>
export default {
  name: 'CoverHome',
  methods: {
      changeLang(){
          if(this.$i18n.locale == 'en'){
              this.$i18n.locale = 'es';
          } else {
              this.$i18n.locale = 'en';
          }
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.wrapperCover{
    background-image: url('../../assets/images/aula_sm.jpg');
    background-size: cover;
    background-position: center;
    height: 650px;
    position: relative;
    align-items: center;
    .content{
        height: 100%;
        width: 48%;
        // background-color: #f2f2f2a2;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 5%;
        @media screen and (max-width: 600px)  {
            width: 100vw;
            background-color: transparent;
            justify-content: center;
            align-items: center;
            padding-right: 0px;
        }
        .text{
            width: 45%;
            background-color: rgba(255, 255, 255, 0.748);
            border-radius: 15px;
            padding: 30px;
            height: fit-content;
            text-align: left;
            display: flex;
            flex-direction: column;
            justify-content: center;
            
            @media screen and (max-width: 750px)  {
                width: 60%;
            }
            @media screen and (max-width: 600px)  {
                background-color: rgba(255, 255, 255, 0.748);
                border-radius: 15px;
                text-align: center;
                padding: 30px;
                height: fit-content;
                display: flex;
                flex-direction: column;
                align-items: center;
                
            }
        }
    }
    .wave{
        position: absolute;
        bottom: -5px;
        left: 0px;
        widows: 100vw;
        img{
            width: 100%;
        }
    }
}
</style>
