<template>
  <div class="wrapper">
      <div class="content">
          <div class="cards">
              <div class="card animate" data-animation="downUp" id="test">
              <div class="icon">
                  <img src="@/assets/images/ico-student.svg" alt="">
              </div>
              <div class="body">
                  <div class="cliente">{{ $t('title_student_banner') }}</div>
                  <router-link :to="'/' + $i18n.locale + '/students'" tag="button" class="green">{{ $t('title_student_button') }}</router-link>
              </div>
          </div>
          <div class="card company animate" data-animation="downUp">
              <div class="icon">
                  <img src="@/assets/images/ico-company.svg" alt="">
              </div>
              <div class="body">
                  <div class="cliente">{{ $t('title_company_banner') }}</div>
                  <router-link :to="'/' + $i18n.locale + '/companies'" tag="button" class="light">{{ $t('title_company_button') }}</router-link>
              </div>
          </div>
          <div class="card company animate" data-animation="downUp">
              <div class="icon">
                  <img src="@/assets/images/ico-institution.svg" alt="">
              </div>
              <div class="body">
                  <div class="cliente">{{ $t('title_institution_banner') }}</div>
                  <router-link :to="'/' + $i18n.locale + '/institutions'" tag="button" class="dark">{{ $t('title_institution_button') }}</router-link>
              </div>
          </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'Clients',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.wrapper{
    background: linear-gradient(90deg, #31928f85, #56828c86), url('../../assets/images/magna_sm.jpg');
    //   -webkit-filter: blur(5px);
    //     -moz-filter: blur(5px);
    //     -o-filter: blur(5px);
    //     -ms-filter: blur(5px);
        backdrop-filter: blur( 10.0px );
-webkit-backdrop-filter: blur( 10.0px );
        overflow: hidden;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-top: 80px;
    .content{
        // height: 200px;
        padding-top: 80px;
        padding-bottom: 80px;
        .cards{
            display: flex;
            justify-content: space-evenly;
            @media screen and (max-width: 560px)  {
                flex-direction: column;
            }
            .card{
            background-color: white;
            border-radius: 10px;
            border: 1px solid #838c3f;
            width: 200px;
            @media screen and (max-width: 560px)  {
                margin: auto;

            margin-bottom: 30px;            }
            &.company{
                border: 1px solid #56828c;
                .icon{
                  top: -7px;
                  img {
                      width: 120%;
                  }  
                }
            }
            &.institutions{
                border: 1px solid #395359;
            }
            .icon{
                margin: auto;
                position: relative;
                top: -8px;
                width: 80px;
                img{
                    width: 100%;
                }
            }
            .body{
                margin-bottom: 15px;
                .cliente {
                    margin-bottom: 10px;
                    font-weight: 600;
                }
            }
        }
        }
    }
}
</style>
