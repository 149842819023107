<template>
  <div class="wrapper">
      <div class="wave" v-if="wave">
          <img src="@/assets/images/waves-inv.svg" alt="">
      </div>
      <div class="content">
          <h2>{{ $t('contact_title_home') }}</h2>
          <div class="flex-content">
              
              <div class="form">
                  <!-- <form action="https://eltoril.us7.list-manage.com/subscribe/post?u=5e1b8e3a75650a6a1e741004a&amp;id=b3ff7cc9a0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate> -->
              <form action="https://formsubmit.co/info@cumlaude.education" method="POST">
                 <!-- <form action="https://formsubmit.co/arnold.b.sebastian@gmail.com" method="POST"> -->
              <!-- <div>
                  <input type="text" name="FNAME" id="mce-FNAME" placeholder="First Name">
              </div> -->
              <div>
                  <input type="text" name="NAME" id="mce-LNAME" placeholder="Name">
              </div>
              <div>
                  <input type="text" name="EMAIL" id="mce-EMAIL" placeholder="E-mail" required>
              </div>
              <div>
                  <textarea name="MESSAGE" id="mce-MESSAGE" cols="30" rows="10" placeholder="What do you want to tell us?" required></textarea>
              </div>
              <input type="hidden" name="_next" :value="'https://www.cumlaude.education/' + $i18n.locale + '/submission' ">
              <button>{{ $t('send_button') }}</button>
              </form>
              
          </div>
          <div class="info">
              <div class="section">
                  <div class="head">{{ $t('contact_address_home') }}</div>
              <div class="data">Carretera N 502 km 93.7, <br>
                  45612 Velada, Toledo</div>
              </div>
              <div class="section">
                  <div class="head">
                      {{ $t('contact_phone_home') }}
                  </div>
                   <div class="data">
                      +34 686 06 95 05
                  </div>
              </div>
               <div class="section">
                   <div class="head">
                      E-mail
                  </div>
                  <div class="data">
                      info@cumlaude.education
                  </div>
               </div>

                  
                 
          </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'Form',
  props: {
      wave: Boolean
  },
//   data() {
//      return {
//          wave: true
//      }  
//     },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.wrapper{
    .content{
        margin-bottom: 70px;
        .flex-content{
            display: flex;
@media screen and (max-width: 700px)  {
                flex-direction: column;
            }
        .form{
            width: 60%;
            @media screen and (max-width: 700px)  {
                 width: 100%;
            }
            input{
                width: calc(100% - 20px);
                border-radius: 5px;
                border: 2px solid #395359;
                padding: 10px 10px;
                margin-bottom: 15px;
            }
            textarea{
                width: calc(100% - 20px);
                border-radius: 5px;
                border: 2px solid #395359;
                padding: 10px 10px;
                margin-bottom: 15px;
            }
            button{
                width: 100%;
            }
        }
        .info{
            width: 35%;
            padding-left: 50px;
            @media screen and (max-width: 700px)  {
                 width: 100%;
                 padding-left: 0px;
                 display: flex;
                 justify-content: space-between;
                 flex-wrap: wrap;
                 margin-top: 50px;
            }
            .head{
                text-align: left;
                font-weight: 600;
                font-size: 1.1em;
            }
            .data{
                text-align: left;
                font-size: 0.9rem;
                margin-bottom: 20px;
            }
        }
        }
    }
    .wave{
        // position: absolute;
        // bottom: -5px;
        // left: 0px;
        widows: 100vw;
        img{
            width: 100%;
        }
    }
}
</style>
