<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png"> -->
    <CoverHome />
    <Philosophy />
    <!-- <Slider /> -->
    <Clients />
    <Partners />
    <Form :wave="true"/>
    <Map />
  </div>
</template>

<script>
// @ is an alias to /src
import CoverHome from '@/components/home/CoverHome.vue'
import Philosophy from '@/components/home/Philosophy.vue'
// import Slider from '@/components/home/Slider.vue'
import Clients from '@/components/home/Clients.vue'
import Partners from '@/components/home/Partners.vue'
import Form from '@/components/home/Form.vue'
import Map from '@/components/home/Map.vue'

export default {
  name: 'Home',
  components: {
    Philosophy,
    CoverHome,
    // Slider,
    Clients,
    Partners,
    Form,
    Map
  },
  mounted() {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }
}
</script>
