<template>
  <div class="sidebarWrapper" id="sidebar">
    <div style="padding: 0 30px">
      <!-- <h1 style="text-align: left" v-on:click="toggleSidebar()">&times;</h1> -->
      <div class="close" v-on:click="toggleSidebar()"><h1>&times;</h1></div>
      <div class="menu">
        <img class="logo" src="@/assets/images/logo-cumlaude.svg" alt="">
        <router-link :to="'/' + $i18n.locale"
          ><h2 v-on:click="toggleSidebar()">Home</h2></router-link
        >

        <router-link :to="'/' + $i18n.locale + '/about'"
          ><h2 v-on:click="toggleSidebar()">{{ $t('nav_about') }}</h2></router-link
        >
        <router-link :to="'/' + $i18n.locale + '/institutions'"
          ><h2 v-on:click="toggleSidebar()">{{ $t('nav_institutions') }}</h2></router-link
        >
        <router-link :to="'/' + $i18n.locale + '/companies'"
          ><h2 v-on:click="toggleSidebar()">{{ $t('nav_companies') }}</h2></router-link
        >

<router-link :to="'/' + $i18n.locale + '/students'"
          ><h2 v-on:click="toggleSidebar()">{{ $t('nav_students') }}</h2></router-link
        >
        <router-link :to="'/' + $i18n.locale + '/contact'"
          ><h2 v-on:click="toggleSidebar()">{{ $t('nav_contact') }}</h2></router-link
        >
        <div class="flags">
          <img v-on:click="changeLang()" class="first" src="../assets/icons/spain-round-icon.svg" alt="Spanish">  
          <img v-on:click="changeLang()" src="../assets/icons/united-kingdom.svg" alt="English">
        </div>
        <!-- <a class="cita" href="https://app.vvmovil.com">Agenda tu cita</a> -->
      </div>
      <!-- <div style="position:relative; right:0; width: 120%;">
        <img src="@/assets/images/landing/main_illustration_car_platform.svg" alt="">
      </div> -->
      
    </div>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  //   data() {
  //   return {
  //     frontendURL: process.env.VUE_APP_FRONTEND_BASE
  //   }
  // },

  methods: {
    toggleSidebar: function () {
      document.getElementById("sidebar").classList.toggle("active");
    },
    changeLang(){
          if(this.$i18n.locale == 'en'){
              this.$i18n.locale = 'es';
          } else {
              this.$i18n.locale = 'en';
          }
      }
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables";
.sidebarWrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 100vw;
  top: 0;
  background:linear-gradient(90deg, #31928f85, #56828c86), url('../assets/images/outside_render_sm.jpg');
    background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

  transition: all 0.5s;
  z-index: 3000;
  &.active {
    left: 0;
  }
  .close{
   width:50px; 
   height:50px; 
   background-color:white;
   border-radius: 50%;
   display: flex;
   justify-content: center;
   align-items: center;
   margin-top: 35px;
   position: relative;
    right: 10px;
    float: right;
   i{
     color: $blue;
     font-size: 1.1rem;
   }
  }
  .flags {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding-bottom: 100px;
        img {
          height: unset;
          width: 35px;
          padding:0 20px
        }
        img:hover {
          cursor: pointer;
        }
        .first {
          border-right: 1px solid white;
        }
      }
  .menu {
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    padding-top: 100px;
    // height: 50%;
    .logo {
      width: 90%; 
      max-width: 500px;
      height:100px
    }
    h2 {
      margin: 30px 0;
      color: white;
      font-size: 1.8rem;
    }
    a{
      text-decoration: none;
    }


  }

}
</style>
